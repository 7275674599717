import apiClient from './apiClient'

export const getClients = async (params) => {
  try {
    const response = await apiClient.get('/clients', { params })
    return response.data
  } catch (error) {
    console.error('Error fetching clients:', error)
    throw error
  }
}

export const getOwners = async (params) => {
  try {
    const response = await apiClient.get('/clients/owners', { params })
    return response.data
  } catch (error) {
    console.error('Error fetching owners:', error)
    throw error
  }
}

export const getClientDetails = async (clientId) => {
  try {
    const response = await apiClient.get(`/clients/${clientId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching company:', error)
    throw error
  }
}

export const saveClientDetails = async (clientId, formData) => {
  try {
    const response = await apiClient.post(`/clients/${clientId}/edit`, formData)
    return response.data
  } catch (error) {
    console.error('Error saving client details:', error)
    throw error
  }
}

export const changeUserStatus = async (userId, status) => {
  try {
    const formData = new FormData()
    formData.append('user_id', userId)
    formData.append('status', status)
    const response = await apiClient.post('/clients/change_status', formData)
    return response.data
  } catch (error) {
    console.error('Error changing user status:', error)
    throw error
  }
}

export const getSubscriptions = async () => {
  try {
    const response = await apiClient.get('/pricing/plans')
    return response.data
  } catch (error) {
    console.error('Error fetching subscriptions:', error)
    throw error
  }
}

export const getLastLoginPeriods = async () => {
  try {
    const response = await apiClient.get('/last_login_periods')
    return response.data
  } catch (error) {
    console.error('Error fetching last login periods:', error)
    throw error
  }
}

export const sendMessage = async (data) => {
  try {
    const formData = new FormData()
    formData.append('message', data.message)

    data.users.forEach(user => {
      formData.append('users[]', user)
    })

    if (data.filters) {
      if (data.filters.subscriptions) {
        data.filters.subscriptions.forEach(sub => {
          formData.append('filters[subscriptions][]', sub)
        })
      }
      if (data.filters.lastLogins) {
        formData.append('filters[lastLogins]', data.filters.lastLogins)
      }
    }

    const response = await apiClient.post('/clients/send_message', formData)
    return response.data
  } catch (error) {
    console.error('Error sending message:', error)
    throw error
  }
}

export const authorizeUser = async (userId) => {
  try {
    const response = await apiClient.post(`/clients/${userId}/authorize`)
    return response.data
  } catch (error) {
    console.error('Error authorizing user:', error)
    throw error
  }
}
