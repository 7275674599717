// router/index.js
import {
  createRouter,
  createWebHistory
} from 'vue-router'
import store from '@/store'
import LoginPage from '../views/LoginPage.vue'
import AdminDashboard from '../views/AdminDashboard.vue'
import ClientsPage from '../views/ClientsPage.vue'
import ClientDetails from '../views/ClientDetails.vue'
import CompaniesPage from '../views/CompaniesPage.vue'
import CompanyDetails from '../views/CompanyDetails.vue'
import MattersList from '../components/company/MattersList.vue'
import TasksList from '../components/company/TasksList.vue'
import ActivitiesList from '../components/company/ActivitiesList.vue'
import BillsList from '../components/company/BillsList.vue'
import EventsList from '../components/company/EventsList.vue'
import ContactsList from '../components/company/ContactsList.vue'
import MatterDetails from '../views/MatterDetails.vue'
import TaskDetails from '../views/TaskDetails.vue'
import ActivityDetails from '../views/ActivityDetails.vue'
import BillDetails from '../views/BillDetails.vue'
import EventDetails from '../views/EventDetails.vue'
import ContactDetails from '../views/ContactDetails.vue'
import ChatPage from '../views/ChatPage.vue'
import HistoryPage from '../views/HistoryPage.vue'
import SubscriptionsPage from '../views/SubscriptionsPage.vue'
import SmsSendingPage from '../views/SmsSendingPage.vue'
import SentMessagesPage from '@/views/SentMessagesPage.vue'
import IncomingMessagesPage from '@/views/IncomingMessagesPage.vue'

const routes = [{
  path: '/',
  redirect: {
    name: 'dashboard'
  },
  meta: {
    requiresAuth: true
  }
},
{
  path: '/login',
  name: 'login',
  component: LoginPage,
  meta: {
    requiresGuest: true
  }
},
{
  path: '/dashboard',
  name: 'dashboard',
  component: AdminDashboard,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/clients',
  name: 'clients',
  component: ClientsPage,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/clients/:clientId',
  name: 'clientDetails',
  component: ClientDetails,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/companies',
  name: 'companies',
  component: CompaniesPage,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/companies/:id',
  name: 'companyDetails',
  component: CompanyDetails,
  meta: {
    requiresAuth: true,
    breadcrumb: [
      { name: 'Companies', path: '/companies' },
      { name: '' }
    ]
  }
},
{
  path: '/companies/:id/matters',
  name: 'mattersList',
  component: MattersList,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:id/tasks',
  name: 'tasksList',
  component: TasksList,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:id/activities',
  name: 'activitiesList',
  component: ActivitiesList,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:id/bills',
  name: 'billsList',
  component: BillsList,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:id/events',
  name: 'eventsList',
  component: EventsList,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:id/contacts',
  name: 'contactsList',
  component: ContactsList,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:companyId/matters/:matterId',
  name: 'matterDetails',
  component: MatterDetails,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:companyId/tasks/:taskId',
  name: 'taskDetails',
  component: TaskDetails,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:companyId/activities/:activityId',
  name: 'activityDetails',
  component: ActivityDetails,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:companyId/bills/:billId',
  name: 'billDetails',
  component: BillDetails,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:companyId/events/:eventId',
  name: 'eventDetails',
  component: EventDetails,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/companies/:companyId/contacts/:contactId',
  name: 'contactDetails',
  component: ContactDetails,
  meta: {
    requiresAuth: true,
    breadcrumb: []
  }
},
{
  path: '/chat',
  name: 'chat',
  component: ChatPage,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/chat/:userId',
  name: 'chatPage',
  component: ChatPage,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/history',
  name: 'history',
  component: HistoryPage,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/subscriptions',
  name: 'subscriptions',
  component: SubscriptionsPage,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/sms',
  name: 'sms',
  component: SmsSendingPage,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/sms/outgoing',
  name: 'sms_outgoing',
  component: SentMessagesPage,
  meta: {
    requiresAuth: true
  }
},
{
  path: '/sms/incoming',
  name: 'sms_incoming',
  component: IncomingMessagesPage,
  meta: {
    requiresAuth: true
  }
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Если требуется аутентификация и пользователь не авторизован
    if (!store.getters.isAuthenticated) {
      next({
        name: 'login'
      })
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    // Если маршрут для гостей и пользователь авторизован
    if (store.getters.isAuthenticated) {
      next({
        name: 'dashboard'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
