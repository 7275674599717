import apiClient from './apiClient'

export const getSubscriptionsList = async (params) => {
  try {
    const response = await apiClient.get('/subscriptions', {
      params
    })
    return response.data
  } catch (error) {
    console.error('Error fetching subscriptions:', error)
    throw error
  }
}

export const updateDueDate = async (subscriptionId, formData) => {
  try {
    const response = await apiClient.post(`/subscriptions/${subscriptionId}/update_due_date`, formData)
    return response.data
  } catch (error) {
    console.error('Error update due date:', error)
    throw error
  }
}
