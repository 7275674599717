<template>
    <div class="sideMenu__container">
        <div class="sideMenu__top">
            <div class="sideMenu__logo">
                <svg width="89" height="95" viewBox="0 0 89 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <use href="#main_logo"></use>
                </svg>
            </div>
            <div class="sideMenu__list">
                <SideMenuItem v-for="item in menuItems" :key="item.name" :icon="item.icon" :name="item.name"
                    :route="item.route" />
            </div>
        </div>

        <div class="sideMenu__bottom">
            <div class="account__box" v-if="user">
                <div class="account__avatar">
                    <img :src="user.avatar || defaultAvatar" alt="">
                </div>
                <div class="account__info">
                    <div class="account__name">{{ user.name }}</div>
                    <div class="account__status">{{ user.role }}</div>
                </div>
            </div>
            <div class="sideMenu__bottomMenu">
                <div class="sideMenu__list">
                    <SideMenuItem v-for="item in bottomMenuItems" :key="item.name" :icon="item.icon" :name="item.name"
                    :route="item.route" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import SideMenuItem from './SideMenuItem.vue'
import defaultAvatar from '@/assets/default_avatar.svg'

export default {
  components: {
    SideMenuItem
  },
  data () {
    return {
      menuItems: [
        { name: 'menu.dashboard', icon: 'DashboardIcon', route: '/dashboard' },
        { name: 'menu.clients', icon: 'ClientsIcon', route: '/clients' },
        { name: 'menu.companies', icon: 'CompaniesIcon', route: '/companies' },
        { name: 'menu.chat', icon: 'ChatIcon', route: '/chat' },
        { name: 'menu.history', icon: 'HistoryIcon', route: '/history' },
        { name: 'menu.subscriptions', icon: 'SubscriptionsIcon', route: '/subscriptions' },
        { name: 'menu.sms', icon: 'SmsIcon', route: '/sms' }
      ],
      bottomMenuItems: [
        // { name: 'menu.settings', icon: 'SettingsIcon', route: '/settings' },
        { name: 'menu.logout', icon: 'LogoutIcon', route: '/logout' }
      ],
      defaultAvatar
    }
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>

<style scoped>
.sideMenu__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background: #fff;
    height: 100vh;
    max-width: 240px;
    width: 100%;
}

.sideMenu__top {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 46px;
    padding: 40px 40px;
    width: 100%;
}

.sideMenu__list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
}

.sideMenu__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 32px;
    padding: 40px 40px;
    width: 100%;
}

.sideMenu__bottomMenu{
    width: 100%;
}

.account__box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 16px;
}

.account__name{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    color: var(--black-text);
}

.account__status{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 111%;
    color: var(--text-color);
}

.account__info{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
}

.account__avatar{
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
}
.account__avatar img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 0.5px solid #e5e5e5;
}
</style>
