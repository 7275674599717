<template>
    <div class="modal__overlay" @click.self="close">
      <div class="modal__content">
        <div class="modal__header">
          <h2>{{ $t('subscriptions.detailsTitle') }}</h2>
          <div class="modal__close" @click="close">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L12.94 1M13 13L1.06 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div class="modal__body">
          <p><strong>{{ $t('subscriptions.id') }}:</strong> {{ subscription.id }}</p>
          <p><strong>{{ $t('subscriptions.owner') }}:</strong> {{ subscription.owner?.name || '-' }}</p>
          <p><strong>{{ $t('subscriptions.action') }}:</strong> {{ subscription.action }}</p>
          <p><strong>{{ $t('subscriptions.issueDate') }}:</strong> {{ subscription.issue_date }}</p>
          <p class="flex g-16"><strong>{{ $t('subscriptions.dueIn') }}:</strong> <DatePicker
                v-model:value="dueInDate"
                format="MM/DD/YYYY HH:mm"
                type="datetime"
            /></p>
          <p><strong>{{ $t('subscriptions.status') }}:</strong> {{ subscription.status }}</p>
          <p><strong>{{ $t('subscriptions.is_trial') }}:</strong> {{ subscription.is_trial ? 'Yes' : 'No' }}</p>
        </div>
        <button @click="updateDueDate">{{ $t('subscriptions.save') }}</button>
      </div>
    </div>
  </template>

<script>
import { updateDueDate } from '@/api'
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import { parse } from 'date-fns'

export default {
  components: { DatePicker },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dueInDate: parse(this.subscription.due_in, 'MM/dd/yyyy HH:mm', new Date())
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async updateDueDate () {
      const formData = new FormData()
      const dueInTimestamp = Math.floor(this.dueInDate.getTime() / 1000)
      formData.append('due_in', dueInTimestamp) // тут передать новую выборанную дату в timestamp
      try {
        const response = await updateDueDate(this.subscription.id, formData)
        console.log('response: ', response)
        if (response.status === 'Success') {
          this.$emit('updated', {
            subscriptionId: this.subscription.id,
            dueIn: dueInTimestamp
          })
          this.close()
        }
      } catch (error) {
        console.error('Failed to update subscribe:', error)
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>

  <style scoped>
  button.w-100 {
      width: 100%;
  }
  </style>
