<template>
    <svg width="20" height="20" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.1545 12.7233H3.1658C2.53065 12.7233 2.00842 12.2011 2.00842 11.5659V3.59133C2.00842 2.95619 2.53065 2.43396 3.1658 2.43396H14.3585C14.9936 2.43396 15.5158 2.95619 15.5158 3.59133V8.30552C15.5158 8.54546 15.3182 8.74306 15.0783 8.74306C14.8383 8.74306 14.6407 8.54546 14.6407 8.30552V3.59133C14.6407 3.45019 14.5137 3.32316 14.3726 3.32316H3.17991C3.03877 3.32316 2.91174 3.45019 2.91174 3.59133V11.5659C2.91174 11.7071 3.03877 11.8341 3.17991 11.8341H11.1686C11.4086 11.8341 11.6062 12.0317 11.6062 12.2716C11.6062 12.5116 11.3945 12.7233 11.1545 12.7233Z"
            fill="#939393" />
        <path
            d="M0.653517 11.0861C0.441802 11.0437 0.300659 10.8461 0.300659 10.6344V3.43612C0.300659 2.01057 1.45803 0.86731 2.86947 0.86731H13.2858C13.4976 0.86731 13.6952 1.00845 13.7375 1.22017C13.794 1.50245 13.5822 1.7424 13.3 1.7424H2.86947C1.93792 1.7424 1.17575 2.50457 1.17575 3.43612V10.6485C1.17575 10.9308 0.935804 11.1425 0.653517 11.0861Z"
            fill="#939393" />
        <path
            d="M8.69865 9.27944C8.40224 9.27944 8.10584 9.16653 7.88001 8.9407L2.446 3.50668C2.27663 3.33731 2.27663 3.05502 2.446 2.88565C2.61537 2.71628 2.89766 2.71628 3.06703 2.88565L8.50104 8.31967C8.59984 8.41847 8.78333 8.41847 8.88213 8.31967L14.3303 2.87154C14.4996 2.70216 14.7819 2.70216 14.9513 2.87154C15.1207 3.04091 15.1207 3.32319 14.9513 3.49257L9.50316 8.9407C9.29145 9.16653 8.99505 9.27944 8.69865 9.27944Z"
            fill="#939393" />
        <path
            d="M12.566 12.3C12.566 12.363 12.5785 12.4254 12.6026 12.4836C12.6267 12.5418 12.662 12.5947 12.7066 12.6393C12.7512 12.6838 12.8041 12.7192 12.8623 12.7433C12.9205 12.7674 12.9829 12.7798 13.0459 12.7798C13.1089 12.7798 13.1713 12.7674 13.2296 12.7433C13.2878 12.7192 13.3407 12.6838 13.3853 12.6393C13.4298 12.5947 13.4652 12.5418 13.4893 12.4836C13.5134 12.4254 13.5258 12.363 13.5258 12.3C13.5258 12.2369 13.5134 12.1745 13.4893 12.1163C13.4652 12.0581 13.4298 12.0052 13.3853 11.9606C13.3407 11.9161 13.2878 11.8807 13.2296 11.8566C13.1713 11.8325 13.1089 11.8201 13.0459 11.8201C12.9829 11.8201 12.9205 11.8325 12.8623 11.8566C12.8041 11.8807 12.7512 11.9161 12.7066 11.9606C12.662 12.0052 12.6267 12.0581 12.6026 12.1163C12.5785 12.1745 12.566 12.2369 12.566 12.3Z"
            fill="#939393" />
        <path
            d="M14.4996 12.2999C14.4996 12.363 14.512 12.4254 14.5361 12.4836C14.5602 12.5418 14.5956 12.5948 14.6402 12.6393C14.6847 12.6839 14.7376 12.7193 14.7959 12.7434C14.8541 12.7675 14.9165 12.7799 14.9795 12.7799C15.0425 12.7799 15.105 12.7675 15.1632 12.7434C15.2214 12.7193 15.2743 12.6839 15.3189 12.6393C15.3634 12.5948 15.3988 12.5418 15.4229 12.4836C15.447 12.4254 15.4594 12.363 15.4594 12.2999C15.4594 12.2369 15.447 12.1745 15.4229 12.1163C15.3988 12.058 15.3634 12.0051 15.3189 11.9605C15.2743 11.916 15.2214 11.8806 15.1632 11.8565C15.105 11.8324 15.0425 11.8199 14.9795 11.8199C14.9165 11.8199 14.8541 11.8324 14.7959 11.8565C14.7376 11.8806 14.6847 11.916 14.6402 11.9605C14.5956 12.0051 14.5602 12.058 14.5361 12.1163C14.512 12.1745 14.4996 12.2369 14.4996 12.2999Z"
            fill="#939393" />
        <path
            d="M14.5562 10.324C14.5561 10.387 14.5685 10.4494 14.5927 10.5077C14.6168 10.5659 14.6521 10.6188 14.6967 10.6634C14.7412 10.7079 14.7941 10.7433 14.8524 10.7674C14.9106 10.7915 14.973 10.804 15.036 10.804C15.0991 10.804 15.1615 10.7915 15.2197 10.7674C15.2779 10.7433 15.3308 10.7079 15.3754 10.6634C15.42 10.6188 15.4553 10.5659 15.4794 10.5077C15.5035 10.4494 15.5159 10.387 15.5159 10.324C15.5159 10.2609 15.5035 10.1985 15.4794 10.1403C15.4553 10.0821 15.42 10.0292 15.3754 9.98458C15.3308 9.94001 15.2779 9.90465 15.2197 9.88053C15.1615 9.85641 15.0991 9.84399 15.036 9.84399C14.973 9.84399 14.9106 9.85641 14.8524 9.88053C14.7941 9.90465 14.7412 9.94001 14.6967 9.98458C14.6521 10.0292 14.6168 10.0821 14.5927 10.1403C14.5685 10.1985 14.5561 10.2609 14.5562 10.324Z"
            fill="#939393" />
    </svg>
</template>

<script>
export default {
  name: 'SmsIcon'
}
</script>
