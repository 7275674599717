<template>
  <div class="main__container">
    <div class="chat__container">
      <div class="chatList__container">
        <div class="chatList__search"></div>
        <div v-if="loadingChatList" class="chatList__box">
          <div v-for="n in 10" :key="n" class="chatList__item ">
            <div class="chatList__itemAvatar shimmer shimmer-avatar"></div>
            <div class="chatList__itemBody">
              <div class="chatList__itemHeader shimmer shimmer-box"></div>
              <div class="chatList__itemFooter shimmer shimmer-box"></div>
            </div>
          </div>
        </div>
        <div v-else class="chatList__box">
          <div v-for="chat in sortedChatList" :key="chat.id" class="chatList__item"
            :class="{ active: selectedChat?.id === chat?.id }" @click="selectChat(chat)">
            <div class="chatList__itemAvatar">
              <img :src="chat.chatPhoto || defaultAvatar" :alt="chat.chatName">
            </div>
            <div class="chatList__itemBody">
              <div class="chatList__itemHeader">
                <div class="chatList__itemTitle">{{ chat.chatName }}</div>
                <div class="time">{{ formatDate(chat?.lastMessageTime) }}</div>
              </div>
              <div class="chatList__itemFooter">
                <div class="chatList__itemText"><b v-if="chat.lastMessage?.senderId == user?.id">You:</b> {{
          chat.lastMessage?.content }}</div>
                <div class="unread"
                  v-if="chat.lastMessage && chat.lastMessage?.senderId != user?.id && !chat.lastMessage?.isRead"></div>
                <div class="read" v-if="chat.lastMessage?.senderId == user?.id && chat.lastMessage?.isRead">
                  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 7.00003L9 19L3.5 13.5L4.91 12.09L9 16.17L19.59 5.59003L21 7.00003Z" fill="#0057BC" />
                    <path d="M26 7.00009L14 19.0001L12.5 17.5L14 16.1701L24.59 5.59009L26 7.00009Z" fill="#0057BC" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chatroom__container" v-if="selectedChat">
        <div class="chatroom__header">
          <div class="chatroom__headerInfo">
            <div class="chatroom__headerTitle">{{ selectedChat.chatName }}</div>
            <div class="chatroom__headerSubtitle">{{ messages.length }} messages</div>
          </div>
          <div class="chatroom__headerAvatar">
            <img :src="selectedChat.chatPhoto || defaultAvatar" :alt="selectedChat.chatName">
          </div>
        </div>
        <div class="chatroom__body">
          <div ref="messagesContainer" class="chatroom__messagesContainer">
            <div v-if="loadingMessages" class="chatroom__daySection">
              <div v-for="item in messageItems" :key="item" :class="['chatroom__messageItem', item.messageType]">
                <div :class="['chatroom__message', 'shimmer', 'shimmer-message', item.widthClass]"></div>
                <div class="chatroom__messageAvatar shimmer shimmer-avatar"></div>
              </div>
            </div>
            <div v-else v-for="group in groupedMessages" :key="group.dateGroup" class="chatroom__daySection">
              <div class="chatroom__dateSection">{{ formatDateGroup(group.messages[0].createdAt) }}</div>
              <div v-for="message in group.messages" :key="message.id" class="chatroom__messageItem"
                :class="{ outgoing: message.isOutgoing, incoming: !message.isOutgoing }">
                <div class="chatroom__message">
                  <div class="chatroom__messageTitle">
                    <a :href="message.authorProfile">{{ message.senderName }}</a>
                  </div>
                  <div v-if="message.type === 'image'" class="chatroom__messageImage">
                    <img :src="message.fileUrl" @click="openImage" alt="Sent image">
                    <div class="chatroom__messageTime">
                      <span :data-message-time="message.timestamp">{{ message.time }}</span>
                      <span data-seen-icon="">
                        <svg width="20" height="20" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21 7.00003L9 19L3.5 13.5L4.91 12.09L9 16.17L19.59 5.59003L21 7.00003Z"
                            fill="#0057BC" />
                          <path d="M26 7.00009L14 19.0001L12.5 17.5L14 16.1701L24.59 5.59009L26 7.00009Z"
                            fill="#0057BC" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div v-else-if="message.type === 'file'" class="chatroom__messageFile">
                    <a href="#" @click="downloadFile(message.fileUrl)">Download File</a>
                    <div class="chatroom__messageTime">
                      <span :data-message-time="message.timestamp">{{ message.time }}</span>
                      <span data-seen-icon="">
                        <svg width="20" height="20" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21 7.00003L9 19L3.5 13.5L4.91 12.09L9 16.17L19.59 5.59003L21 7.00003Z"
                            fill="#0057BC" />
                          <path d="M26 7.00009L14 19.0001L12.5 17.5L14 16.1701L24.59 5.59009L26 7.00009Z"
                            fill="#0057BC" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div v-else class="chatroom__messageText">{{ message.content }}
                    <div class="chatroom__messageTime">
                      <span :data-message-time="message.createdAt">{{ formatDateMessage(message.createdAt) }}</span>
                      <span v-if="message.isOutgoing && message.isRead" data-seen-icon="">
                        <svg width="20" height="20" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21 7.00003L9 19L3.5 13.5L4.91 12.09L9 16.17L19.59 5.59003L21 7.00003Z"
                            fill="#0057BC" />
                          <path d="M26 7.00009L14 19.0001L12.5 17.5L14 16.1701L24.59 5.59009L26 7.00009Z"
                            fill="#0057BC" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-if="message.isOutgoing" class="chatroom__messageAvatar">
                  <img :src="message.senderPhoto || defaultAvatar" :alt="message.senderName">
                </div>
              </div>
            </div>
          </div>
          <div class="chatroom__footer">
            <form @submit.prevent="sendMessage">
              <div class="chatroom__sendForm">
                <div class="chatroom__sendFormAction">
                  <div class="chatroom__sendFormActionAttach">
                    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.12871 6.99999V13.743C4.1365 14.236 4.33784 14.7063 4.68928 15.0522C5.04073 15.3982 5.51409 15.592 6.00723 15.592C6.50036 15.592 6.97373 15.3982 7.32517 15.0522C7.67661 14.7063 7.87795 14.236 7.88574 13.743L7.89183 4.90468C7.89695 4.48324 7.81836 4.06498 7.66062 3.67414C7.50288 3.2833 7.26913 2.92766 6.97292 2.62783C6.6767 2.32801 6.32392 2.08996 5.93503 1.92749C5.54613 1.76502 5.12885 1.68136 4.70738 1.68136C4.28591 1.68136 3.86863 1.76502 3.47974 1.92749C3.09084 2.08996 2.73806 2.32801 2.44185 2.62783C2.14564 2.92766 1.91189 3.2833 1.75415 3.67414C1.59641 4.06498 1.51782 4.48324 1.52293 4.90468V13.8025C1.51434 14.3958 1.62379 14.9849 1.8449 15.5356C2.06602 16.0862 2.39439 16.5874 2.81093 17.01C3.22747 17.4326 3.72386 17.7682 4.27125 17.9973C4.81864 18.2264 5.40611 18.3443 5.99949 18.3443C6.59288 18.3443 7.18034 18.2264 7.72773 17.9973C8.27512 17.7682 8.77151 17.4326 9.18805 17.01C9.60459 16.5874 9.93296 16.0862 10.1541 15.5356C10.3752 14.9849 10.4846 14.3958 10.4761 13.8025V5.48781"
                        stroke="#7E7E7E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
                    </svg>
                    <input type="file" name="attach_file_message" id="sendMessageFile" @change="sendFile">
                  </div>
                </div>
                <div class="chatroom__sendFormTextarea">
                  <textarea v-model="currentMessage" placeholder="Say something..." @keyup.enter="sendOnEnter"
                    style="overflow: hidden; overflow-wrap: break-word; height: 40px;"></textarea>
                </div>
                <div class="chatroom__sendFormAction">
                  <div class="chatroom__sendFormActionSend" @click="sendMessage">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4 10L1 19L19 10L1 1L4 10ZM4 10H10" stroke="#7E7E7E" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VueEasyLightbox :imgs="lightboxImages" :index="imageIndex" :visible="showLightbox" @hide="closeLightbox">
  </VueEasyLightbox>
</template>

<script>
import { mapState } from 'vuex'
import Message from '@/models/Message'
import defaultAvatar from '@/assets/default_avatar.svg'
import VueEasyLightbox from 'vue-easy-lightbox'
import { format, isToday, isThisWeek, differenceInDays } from 'date-fns'

export default {
  components: {
    VueEasyLightbox
  },
  data () {
    return {
      socket: null,
      messages: [],
      currentMessage: '',
      chatList: [],
      defaultAvatar,
      selectedChat: null,
      lightboxImages: [],
      showLightbox: false,
      imageIndex: 0,
      chatId: null,
      isNewChat: true,
      clientId: null,
      loadingChatList: false,
      loadingMessages: false
    }
  },
  created () {
    this.clientId = this.$route.params.userId
    this.loadingChatList = true
    this.connectToSocket()
  },
  mounted () {

  },
  computed: {
    ...mapState(['user']), // тут мы получаем id администратора
    groupedMessages () {
      const grouped = []
      this.messages.reduce((acc, message) => {
        const dateGroup = this.formatDateGroup(message.createdAt)
        let lastGroup = acc.find(g => g.dateGroup === dateGroup)
        if (!lastGroup) {
          lastGroup = {
            dateGroup,
            messages: []
          }
          acc.push(lastGroup)
        }
        lastGroup.messages.push({ ...message })
        return acc
      }, grouped)
      return grouped
    },
    sortedChatList () {
      return [...this.chatList].sort((a, b) => {
        return new Date(b.lastMessageTime) - new Date(a.lastMessageTime)
      })
    },
    messageItems () {
      return Array.from({ length: 7 }, () => ({
        widthClass: this.getRandomWidthClass(),
        messageType: this.isOutgoing()
      }))
    }
  },
  methods: {
    getRandomWidthClass () {
      const widths = ['w-5', 'w-10', 'w-15', 'w-20', 'w-25', 'w-30', 'w-40', 'w-45', 'w-50', 'w-60', 'w-70', 'w-80', 'w-90', 'w-100']
      return widths[Math.floor(Math.random() * widths.length)]
    },
    isOutgoing () {
      return Math.random() < 0.5 ? 'outgoing' : 'incoming'
    },
    connectToSocket () {
      this.$socket.emit('updateChatList')
      this.loadingChatList = false
      this.$socket.on('newMessage', (data) => {
        console.log('New message: ', data)
        data.isOutgoing = data.senderId === parseInt(this.user.id)
        const message = new Message(data)
        if (message.type === 'image') {
          this.lightboxImages.push({ src: message.fileUrl })
        }
        if (this.selectedChat?.id === data.chatId) {
          this.$socket.emit('markAsRead', { userId: this.user.id, chatId: data.chatId })
          this.messages.push(message)
        }
        this.updateChatInList(message)
        this.scrollToBottom()
      })

      this.$socket.on('updateChatList', (chats) => {
        console.log('Received chat list:', this.clientId, chats)
        this.chatList = chats
        if (this.clientId) {
          const chat = chats.find(chat => chat.members.some(member => member.userId === parseInt(this.clientId)))
          if (chat) {
            this.selectChat(chat)
          } else {
            this.$socket.emit('joinChat', { userId: parseInt(this.user.id), clientId: this.clientId }, (data) => {
              this.$socket.emit('updateChatList')
            })
          }
        }
      })

      this.$socket.on('newChat', (chatId) => {
        this.$socket.emit('joinChat', { userId: this.user.id, clientId: null, chatId: chatId }, (data) => {
          this.$socket.emit('updateChatList')
        })
      })

      this.$socket.on('messagesRead', this.handleMessagesRead)
    },
    handleMessagesRead ({ chatId, userId }) {
      if (this.selectedChat && this.selectedChat.id === chatId) {
        this.messages.forEach(message => {
          if (message.senderId !== userId) { // Обновляем статус прочтения для всех сообщений, кроме отправленных самим пользователем
            message.isRead = true
          }
        })
      }
    },
    sendOnEnter (event) {
      event.preventDefault()
      if (this.currentMessage.trim() !== '') {
        this.sendMessage()
      }
    },
    sendMessage () {
      if (this.currentMessage.trim() !== '') {
        const message = {
          content: this.currentMessage,
          senderId: parseInt(this.user.id),
          chatId: this.selectedChat.id,
          type: 'text',
          status: 'sent'
        }

        this.$socket.emit('sendMessage', message)
        this.currentMessage = ''
        this.scrollToBottom()
      }
    },
    sendFile (file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('chatId', this.selectedChat.id)
      this.$socket.emit('sendFile', formData)
    },
    selectChat (chat) {
      this.loadingMessages = true
      this.selectedChat = chat
      this.markMessagesAsRead(chat.id)
      this.$router.push(`/chat/${chat.clientId}`)
      this.messages = [] // Clear previous messages
      this.$socket.emit('joinChat', { userId: this.user.id, clientId: null, chatId: chat.id }, (data) => {
        setTimeout(() => {
          this.messages = data.messages
          this.scrollToBottom()
          this.loadingMessages = false
        }, 400)
      })
    },
    updateChatInList (message) {
      const index = this.chatList.findIndex(chat => chat.id === message.chatId)
      if (index !== -1) {
        this.chatList[index].lastMessage = message
        this.chatList[index].lastMessageTime = message.createdAt
        // После обновления списка чатов Vue автоматически пересчитает sortedChatList
      }
    },
    markMessagesAsRead (chatId) {
      this.$socket.emit('markAsRead', { userId: this.user.id, chatId })
    },
    downloadFile (url) {
      window.open(url, '_blank')
    },
    openImage (index) {
      this.imageIndex = index
      this.showLightbox = true
    },
    closeLightbox () {
      this.showLightbox = false
    },
    formatDate (timestamp) {
      if (!timestamp) {
        return ''
      }
      const date = new Date(timestamp * 1000)

      if (isToday(date)) {
        return format(date, 'HH:mm')
      } else if (isThisWeek(date) && differenceInDays(new Date(), date) <= 7) {
        return format(date, 'EEE') // Outputs Mon, Tue, etc.
      } else {
        return format(date, 'MM.dd.yyyy')
      }
    },
    formatDateMessage (timestamp) {
      const date = new Date(timestamp * 1000)
      return format(date, 'HH:mm')
    },
    formatDateGroup (timestamp) {
      const date = new Date(timestamp * 1000)
      return format(date, 'd MMMM') // "10 July"
    },
    scrollToBottom () {
      this.$nextTick(() => {
        const container = this.$refs.messagesContainer
        if (container) {
          container.scrollTop = container.scrollHeight
        }
      })
    }
  },
  watch: {
    messages () {
      this.scrollToBottom()
    }
  }
}
</script>

<style scoped>
/* Общие контейнеры */
.chat__container {
  display: flex;
  gap: 24px;
  width: 100%;
}

.chatList__container {
  max-width: 354px;
  min-width: 354px;
  background: #ffffff;
  border-radius: 24px;
  padding: 24px 16px;
  overflow-x: hidden;
  height: calc(100vh - 40px);
  overflow-y: auto;
}

.chatroom__container {
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Поиск и список чатов */

.chatList__box {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.chatList__item {
  gap: 8px;
  display: grid;
  grid-template-columns: 52px 1fr;
  padding: 8px;
  border-radius: 10px;
}

.chatList__item:hover {
  cursor: pointer;
  background: var(--background);
}

.chatList__item.active {
  background: var(--background);
}

.chatList__itemAvatar {
  width: 52px;
  min-width: 52px;
  height: 52px;
  position: relative;
}

.chatList__itemAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.chatList__itemHeader {
  display: flex;
  justify-content: space-between;
}

.chatList__itemFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatList__itemTitle {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: var(--black-text);
}

.chatList__itemText {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7e7e7e;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.time {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #7e7e7e;
}

/* Комната чата и её элементы */
.chatroom__header {
  background: #ffffff;
  border-radius: 24px;
  padding: 24px 16px 16px;
  display: flex;
  justify-content: space-between;
}

.chatroom__headerInfo {
  display: flex;
  flex-direction: column;
}

.chatroom__headerTitle {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: var(--black-text);
}

.chatroom__headerSubtitle {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7e7e7e;
}

.chatroom__headerAvatar {
  width: 42px;
  min-width: 42px;
  height: 42px;
  position: relative;
}

.chatroom__headerAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.chatroom__body {
  position: relative;
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 24px;
}

.chatroom__messagesContainer {
  padding: 24px 16px;
  border-radius: 24px;
  background: #ffffff;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  gap: 8px;
  flex-flow: column nowrap;
  position: relative;
}

.chatroom__daySection {
  display: grid;
}

.chatroom__daySection:first-child {
  margin-top: auto !important;
}

.chatroom__dateSection {
  display: block;
  color: #ffffff;
  line-height: 20px;
  position: sticky;
  z-index: 2;
  background-color: rgb(0 0 0 / 17%);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  max-width: 150px;
  margin: 0px auto 8px;
  border-radius: 10px;
  padding: 0px 8px;
  top: 4px;
  bottom: 0px;
  font-size: 12px;
}

.chatroom__messageItem {
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
  position: relative;
  animation-fill-mode: none !important;
  transition: 0.2s all;
  align-items: end;
}

.chatroom__messageItem.outgoing {
  justify-content: flex-end;
}

.chatroom__messageAvatar {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.chatroom__messageItem.incoming .chatroom__messageAvatar {
  display: none;
}

.chatroom__messageAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chatroom__message {
  background: var(--blackground-of-avatar);
  max-width: 444px;
  border-radius: 24px 24px 24px 8px;
  position: relative;
  min-width: 110px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  transition: 0.2s all;
  font-weight: 500;
  font-size: 12px;
  overflow: hidden;
  width: auto;
}

.outgoing .chatroom__message {
  border-radius: 24px 24px 8px 24px;
}

.chatroom__messageTitle {
  display: none;
  padding: 8px 12px 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

.chatroom__messageText {
  word-wrap: break-word;
  white-space: pre-line;
  min-width: 36px;
  width: 100%;
  padding: 6px 14px;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  font-family: var(--font-family);
}

.chatroom__messageImage img {
  max-height: 320px;
  height: 100%;
}

.chatroom__messageTime {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
  z-index: 10;
  margin-left: 8px;
  margin-top: auto;
  float: right;
  padding: 0px 0px 0px 0px;
  font-style: italic;
}

.chatroom__messageTime span {
  font-size: 12px;
  line-height: 16px;
  font-family: var(--font-family);
  font-weight: 400;
  color: #7e7e7e;
}

.chatroom__footer {
  border-radius: 16px;
  padding: 16px;
  background: #ffffff;
}

.chatroom__sendForm {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.chatroom__sendFormAction {
  align-items: flex-end;
  padding: 8px;
  gap: 16px;
  position: relative;
  z-index: 2002;
}

.chatroom__sendFormActionAttach {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 100%;
}

.chatroom__sendFormActionAttach:hover {
  cursor: pointer;
}

.chatroom__sendFormActionAttach input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  opacity: 0;
  outline: 0;
  cursor: inherit;
  display: block;
}

.chatroom__sendFormTextarea {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.chatroom__sendFormTextarea textarea {
  background: transparent !important;
  width: 100% !important;
  padding: 10px 16px 0px !important;
  outline: none;
  resize: none !important;
  padding: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  border: none;
  outline: none;
  height: 40px;
  max-height: 300px;
  border-radius: 0px !important;
  min-height: 40px;
}

.chatroom__sendFormActionSend {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  position: relative;
  z-index: 2002;
}

.chatroom__sendFormActionSend:hover {
  cursor: pointer;
}

.chatroom__sendFormActionAttach:hover svg path,
.chatroom__sendFormActionSend:hover svg path {
  stroke: var(--main-color) !important;
}

.textarea__counter {
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  font-size: 12px;
}

.unread {
  width: 6px;
  height: 6px;
  min-width: 6px;
  max-width: 6px;
  border-radius: 50%;
  background-color: #0158b4;
}

.shimmer-message {
  max-width: 400px;
  max-height: 100px;
  width: 100%;
  height: 60px;
}
</style>
